<template>
<!--  <div class="loadingProgress_background">-->
<!--    <div class="spinner"></div>-->
<!--  </div>-->
  <div class="loadingProgress_background">
    <div class="progress-pie-chart" :class="{ 'gt-50': percentage > 50 }">
      <div class="ppc-progress">
        <div
            class="ppc-progress-fill"
            :style="{ transform: `rotate(${degree}deg)` }"
        ></div>
      </div>
      <div class="ppc-percents">
        <span>{{ percentage }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";

export default {
  data() {
    return {
      progress: 0, // 현재 진행 상태
      max: 1, // 최대 값
    };
  },
  computed: {
    // 퍼센트 계산
    percentage() {
      return this.max > 0 ? Math.round((this.progress / this.max) * 100) : 0;
    },
    // 360도 기준 회전 각도
    degree() {
      return (360 * this.percentage) / 100;
    },
  },
  methods: {
    updateProgress() {
      if (this.common.loadingProgress == true){
        if (this.socketStore.excelProgressTotal != '') {
          this.progress = this.socketStore.excelProgressStatus;
          this.max = this.socketStore.excelProgressTotal;
        }
      }else{
        clearInterval(this.interval);
      }

    },
  },
  mounted() {
    // 1초마다 전역 변수 값 체크
    this.interval = setInterval(this.updateProgress, 1000);
  },
  beforeUnmount() {
    // 컴포넌트가 해제되면 setInterval 정리
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/common/loadingProgress.scss';
</style>
