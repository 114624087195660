<template>
  <div id="app">
    <router-view />
    <Loading v-if="common.loading" />
    <LoadingProgress v-if="common.loadingProgress" />
    <Dialog v-if="common.dialog.open" />
    <Toast />
  </div>
</template>

<script>
import Loading from '@/components/common/Loading';
import LoadingProgress from '@/components/common/LoadingProgress';
import Dialog from '@/components/common/Dialog';
import Toast from '@/components/common/Toast';

export default {
  name: 'App',
  components: {
    Loading,
    LoadingProgress,
    Dialog,
    Toast
  }
};
</script>
<style lang="scss">
@import './assets/style/globalStyle.scss';
@import 'vue2-datepicker/index.css';
</style>
